import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataRouteService {

    dataRoute$: BehaviorSubject<any>;

    constructor() {
        this.dataRoute$ = new BehaviorSubject(null);
    }

    get dataRoute() {
        return this.dataRoute$.getValue();
    }

    set dataRoute(data: any) {
        this.dataRoute$.next(data);
    }

}