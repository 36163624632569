import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        injector.get(LOCATION_INITIALIZED, Promise.resolve(null))
            .then(() => {
                const langToSet = 'it';
                translate.setDefaultLang(langToSet);
                translate.use(langToSet).subscribe(() => {
                    console.log(`Successfully initialized '${langToSet}' language.'`);
                }, err => {
                    console.error(`Problem with '${langToSet}' language initialization.'`);
                }, () => {
                    resolve(null);
                });
            });
    });
}