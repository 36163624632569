import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { User } from '@models/user.model';
import { RequestService } from '@shared/services/request.service';
import { Router } from '@angular/router';
import { JwtTokenService } from './jwt/jwt-token.service';
import { UserService } from './user.service';

@Injectable()
export class AuthService {

    isLoggedIn$: BehaviorSubject<boolean>;

    constructor(private requestService: RequestService, private router: Router,
        private userService: UserService, private jwtTokenService: JwtTokenService) {
        this.isLoggedIn$ = new BehaviorSubject(false);
    }

    get isLoggedIn() {
        return this.isLoggedIn$.getValue();
    }

    set isLoggedIn(value: boolean) {
        this.isLoggedIn$.next(value);
    }

    signIn(email: string, password: string) {
        return this.requestService.execRequest('post', 'authenticate', { email, password })
            .pipe(map(auth => {
                if (auth && auth.jwtToken) {
                    this.jwtTokenService.token = auth.jwtToken;
                    this.userService.consumeUser(auth.user);
                    if (!this.userService.isInvalid) {
                        this.isLoggedIn = true;
                        this.router.navigate(['/practices']);
                    } else {
                        this.router.navigate(['/access/no-role']);
                    }
                }

                return auth;
            }));
    }

    signUp(newUser: User) {
        return this.requestService.execRequest('post', 'register', newUser);
    }

    logout() {
        this.userService.resetUser();
        this.isLoggedIn = false;
        this.jwtTokenService.token = null;
        this.forcePublicRedirect();
    }

    forcePublicRedirect() {
        // go to index
        return this.router.navigate(['/access']);
    }

    initAuth() {
        return this.getCurrentUser().pipe(
            tap((user) => {
                this.userService.consumeUser(user);
                if (!this.userService.isInvalid) {
                    this.isLoggedIn = true;
                }
            }),
            catchError(() => this.forcePublicRedirect())).toPromise();
    }

    getCurrentUser() {
        return this.jwtTokenService.token ? this.requestService.execRequest('get', 'user') : throwError({});
    }

}