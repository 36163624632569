import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@security/_services/auth.guard';
import { NotFoundComponent } from '@layout/not-found/not-found.component';
import { PreloadStrategy } from '@security/_services/preload.strategy';
import { StructureComponent } from '@layout/structure/structure.component';
import { VerificationsComponent } from '@pages/verifications/verifications/verifications.component';
import { VerificationsResolver } from '@pages/verifications/_services/verifications.resolver';
import { VerificationsRoutes } from '@pages/verifications/varifications-routes';

const routes: Routes = [
  {
    path: '',
    component: StructureComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'practices'
      },
      {
        path: 'practices',
        loadChildren: () => import('./pages/practices/practices.module').then(m => m.PracticesModule),
        data: { preload: true, menu: true }
      },
      {
        path: 'intervention',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/intervention/intervention.module').then(m => m.InterventionModule),
        data: { preload: true, menu: true }
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        data: { preload: true, menu: true }
      },
      {
        path: 'verification/:practiceId',
        canActivate: [AuthGuard],
        resolve: {
          practice: VerificationsResolver
        },
        component: VerificationsComponent,
        data: { menu: true },
        children: [...VerificationsRoutes]
      },
      {
        path: 'cms',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule),
        data: { preload: true, menu: false }
      },
    ]
  },
  {
    path: 'access',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/access/access.module').then(m => m.AccessModule),
    data: { preload: true, public: true }
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    enableTracing: false,
    preloadingStrategy: PreloadStrategy,
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: [PreloadStrategy, VerificationsResolver]
})
export class AppRoutingModule { }
