<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 d-none d-md-block">
      <div class="card">
        <app-menu></app-menu>
      </div>
    </div>
    <div class="col-sm-12 col-md-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
