import { Component } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { MenuService } from '../../_services/menu.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ["./mobile-menu.component.scss"],
  animations: [
    trigger('slide', [
      state('slideIn', style({ 'pointer-events': 'auto', transform: 'translateY(0)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class MobileMenuComponent {

  menu$: BehaviorSubject<boolean>;
  menuVoices$: BehaviorSubject<any>

  constructor(private utils: UtilsService, private menuService: MenuService) {
    this.menu$ = this.utils.menu$;
    this.menuVoices$ = this.menuService.menuVoices$;
  }

  closeMenu() {
    this.menu$.next(false);
  }

}
