import { NgModule } from '@angular/core';

import { DesktopMenuComponent } from './_components/desktop-menu/desktop-menu.component';
import { MobileMenuComponent } from './_components/mobile-menu/mobile-menu.component';
import { MenuComponent } from './_components/menu/menu.component';
import { SharedModule } from '@shared/shared.module';

import { MenuService } from './_services/menu.service';

@NgModule({
    declarations: [
        DesktopMenuComponent,
        MobileMenuComponent,
        MenuComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        DesktopMenuComponent,
        MobileMenuComponent,
        MenuComponent
    ],
    providers: [MenuService]
})
export class MenuModule { }
