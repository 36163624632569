import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable()
export class NoteService {


    constructor(private requestService: RequestService) {
    }

    getNotes(practiceId: number) {
        return this.requestService.execRequest('get', `practice/${practiceId}/notes`);
    }

    saveNote(practiceId: number, note: {
        text: string,
        verificationTypeId: number
    }){
        return this.requestService.execRequest('post', `practice/${practiceId}/notes`, note)
    }

}