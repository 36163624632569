import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VerificationsService } from './verifications.service';

@Injectable()
export class VerificationsResolver implements Resolve<any> {

    constructor(private verificantionsService: VerificationsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | boolean {

        const practiceId = route.params['practiceId'];
        return this.verificantionsService.getVerifications(practiceId)
            .pipe(tap((verifications) => this.verificantionsService.initModel(verifications, practiceId)));
    }
}