import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoteService } from '@shared/services/note.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-note-modal',
    templateUrl: 'note-modal.component.html',
    styleUrls: ['note-modal.component.scss'],
    providers: [NoteService]
})
export class NoteModalComponent {

    inLoading: boolean = false;
    currentNote: string;
    practiceId: number;
    currentVerificationTypeId: number;
    verifications: number[];

    notes;

    constructor(
        private noteService: NoteService,
        public dialogRef: MatDialogRef<NoteModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.practiceId = data.practiceId;
        this.verifications = data.verifications;
        this.currentVerificationTypeId = this.verifications[0];
        this.getNotes();
    }

    getNotes() {
        this.inLoading = true;
        this.noteService.getNotes(this.practiceId)
            .pipe(finalize(() => this.inLoading = false))
            .subscribe((notes) => this.notes = notes);
    }

    save() {
        this.inLoading = true;

        this.noteService.saveNote(this.practiceId, {
            text: this.currentNote,
            verificationTypeId: this.currentVerificationTypeId
        }).pipe(finalize(() => this.inLoading = false))
            .subscribe(() => {
                this.notes.push({
                    text: this.currentNote,
                    time: new Date(),
                    verificationTypeId: 1,
                    myNote: true,
                    user: null
                })
                this.currentNote = '';
            })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
