<h1 class="my-5">
  {{'verifications.title' | translate : { id: (verifications$ | async)?.id} }}
</h1>
<div *ngIf="verifications$ | async as verifications" class="d-flex align-items-center justify-content-around justify-content-md-between flex-wrap mb-5">

  <app-practice-states-stepper (onSelectState)="selectedState($event)" mode="extended" operation="navigation" class=" mt-4 mt-sm-0" [requirementsState]="verifications.requirementsState || 1" [salState]="verifications.salState || 0" [endWorksState]="verifications.endWorksState || 0">
  </app-practice-states-stepper>

  <div *ngIf="dataRoute$ | async as dataRoute" class="current-group-container mt-4 mt-sm-0">
    <div class="current-group" [ngClass]="'state-' + (verifications[dataRoute.groupName + 'State'] || 1)" role="button" [matMenuTriggerFor]="groupInfo">
      <fa-icon class="help-icon mr-2" [icon]="['far', 'question-circle']"></fa-icon>
      <span>{{'verifications.' + dataRoute.groupName + '-group' | translate}}</span>
    </div>
    <mat-menu #groupInfo="matMenu" xPosition="before">
      <div class="state-description">
        <span [innerHtml]="('practice.states-descriptions.' + (verifications[dataRoute.groupName + 'State'] || 1)) | translate : { verify: ('verifications.' + dataRoute.groupName + '-group' | translate) } "></span>
      </div>
    </mat-menu>

    <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" class="group-operations">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button class="menu-item" mat-menu-item (click)="openNote(dataRoute.groupName)">
        <mat-icon class="mr-2">speaker_notes</mat-icon>
        {{'operations.note' | translate}}
      </button>
      <button *ngIf="('ROLE_ADMIN' | roleValidator) || ('ROLE_VALIDATOR' | roleValidator)" class="menu-item" mat-menu-item (click)="verifyGroup(dataRoute.groupName)" [disabled]="!enableConfirmRequirements">
        <mat-icon class="mr-2">verified</mat-icon>
        {{'operations.confirm' | translate}} {{'verifications.' + dataRoute.groupName + '-group' | translate}}{{currentPart}}
      </button>


      <button *ngIf="('ROLE_ADMIN' | roleValidator) || ('ROLE_VALIDATOR' | roleValidator)" class="menu-item" mat-menu-item (click)="rejectVerifyGroup(dataRoute.groupName)" [disabled]="!enableConfirmRequirements">
        <mat-icon class="mr-2">thumb_down_off_alt</mat-icon>
        {{'operations.reject' | translate}} {{'verifications.' + dataRoute.groupName + '-group' | translate}}{{currentPart}}
      </button>

      <button *ngIf="('ROLE_ADMIN' | roleValidator) ||  ('ROLE_TECHNICAL' | roleValidator)" class="menu-item" mat-menu-item (click)="unlockNextGroup(dataRoute.groupName)" [disabled]="!enableSendConfirmRequirements">
        <mat-icon class="mr-2">verified</mat-icon>
        {{'operations.send' | translate}} {{'verifications.' + dataRoute.groupName + '-group' | translate}}{{currentPart}}
      </button>
    </mat-menu>
  </div>
</div>

<router-outlet></router-outlet>
