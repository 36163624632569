<nav>
  <ol *ngIf="mode == 'compact'" class="cd-breadcrumb triangle custom-icons" [ngClass]="mode">
    <li role="button" (click)="onGroupClick('requirements', requirementsState)" [class.disabled]="!requirementsState" [ngClass]="'state-' + requirementsState" #requirementsMenu [matMenuTriggerFor]="infoMenu">
      <span>
        {{'verifications.requirements' | translate}}
      </span>
    </li>
    <li role="button" (click)="onGroupClick('sal', salState)" [class.disabled]="!salState" [ngClass]="'state-' + salState" #salMenu [matMenuTriggerFor]="infoMenu">
      <span>
        {{'verifications.sal' | translate}}
      </span>
    </li>
    <li role="button" (click)="onGroupClick('endWorks', endWorksState)" [class.disabled]="!endWorksState" [ngClass]="'state-' + endWorksState" #endWorksMenu [matMenuTriggerFor]="infoMenu">
      <span>
        {{'verifications.end-works' | translate}}
      </span>
    </li>
  </ol>


  <ol *ngIf="mode == 'extended'" class="cd-breadcrumb triangle custom-icons" [ngClass]="mode">
    <li role="button" (click)="onGroupClick('requirements', requirementsState)" [class.disabled]="!requirementsState" [ngClass]="'state-' + requirementsState" #requirementsMenu [matMenuTriggerFor]="infoMenu">
      <span>
        <fa-icon *ngIf="!requirementsState" [icon]="['fas', 'ban']" class="step-icon mr-2">
        </fa-icon>
        {{'verifications.requirements-group' | translate}}
      </span>
    </li>
    <li role="button" (click)="onGroupClick('sal', salState)" [class.disabled]="!salState" [ngClass]="'state-' + salState" #salMenu [matMenuTriggerFor]="infoMenu">
      <span>
        <fa-icon *ngIf="!salState" [icon]="['fas', 'ban']" class="step-icon mr-2"></fa-icon>
        {{'verifications.sal-group' | translate}}
      </span>
    </li>
    <li role="button" (click)="onGroupClick('endWorks', endWorksState)" [class.disabled]="!endWorksState" [ngClass]="'state-' + endWorksState" #endWorksMenu [matMenuTriggerFor]="infoMenu">
      <span>
        <fa-icon *ngIf="!endWorksState" [icon]="['fas', 'ban']" class="step-icon mr-2"></fa-icon>
        {{'verifications.endWorks-group' | translate}}
      </span>
    </li>
  </ol>

  <mat-menu #infoMenu="matMenu" [overlapTrigger]="false">
    <div class="state-description">
      <span [innerHtml]="'practice.states-descriptions.' + (currentState || 1) | translate :
                { verify: ('verifications.' + currentGroupName + '-group' | translate) } "></span>
    </div>
  </mat-menu>
</nav>
