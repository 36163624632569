import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisableControlDirective } from './disable-control/disable-control.directive';
import { DndDirective } from './dnd/dnd.directive';
import { UppercaseDirective } from './uppercase/uppercase.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        DndDirective,
        UppercaseDirective,
        DisableControlDirective
    ],
    exports: [
        DndDirective,
        UppercaseDirective,
        DisableControlDirective
    ]
})
export class SharedDirectivesModule { }
