<app-header [menu]="hasMenu"></app-header>

<main>
    <ng-container *ngIf="hasMenu; else menu">
        <app-mobile-menu></app-mobile-menu>
        <app-desktop-menu></app-desktop-menu>
    </ng-container>
    <ng-template #menu>
        <router-outlet></router-outlet>
    </ng-template>
</main>

<app-page-loader></app-page-loader>

<app-footer></app-footer>