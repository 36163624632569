import { Component } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { VerificationsGroups, IVerifications } from '@models/verifications/verifications.model';
import { VerificationsService } from '@pages/verifications/_services/verifications.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-end-works-group',
    templateUrl: './end-works-group.component.html',
    styleUrls: ['./end-works-group.component.scss']
})
export class EndWorksGroupComponent {

    verificationsGroups$: BehaviorSubject<VerificationsGroups>;
    verifications$: BehaviorSubject<IVerifications>;
    inLoading: boolean;

    constructor(private router: Router, private verificantionsService: VerificationsService) {
        this.verificationsGroups$ = this.verificantionsService.verificationsGroups$;
        this.verifications$ = this.verificantionsService.verifications$;

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                this.inLoading = true;
            } else if (event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError) {
                this.inLoading = false;
            }
        });
    }

}
