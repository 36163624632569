<h1 mat-dialog-title>{{'note.title' | translate}}</h1>
<div mat-dialog-content class="p-0 mesgs">

    <div *ngIf="inLoading; else notesTpl" class="loader-container">
        <app-local-loader></app-local-loader>
    </div>
    <ng-template #notesTpl>
        <div class="history">
            <ng-container *ngFor="let note of notes">
                <div *ngIf="!note.myNote" class="incoming_msg">
                    <div class="incoming_msg_img">
                        {{note.creatorName[0]}}{{note.creatorSurname[0]}}
                    </div>
                    <div class="received_msg">
                        <div class="received_withd_msg">
                            <p><strong>{{note.verificationType}}</strong> -
                                {{note.text}}</p>
                            <span class="time_date">{{note.creationDate | date : 'h:mm | d MMM y'}}</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="note.myNote" class="outgoing_msg">
                    <div class="sent_msg">
                        <p><strong>{{'verifications.codes.' + note.verificationTypeId | translate}}</strong> -
                            {{note.text}}</p>
                        <span class="time_date">{{note.creationDate | date : 'h:mm | d MMM y'}}</span>
                    </div>
                </div>
            </ng-container>

        </div>
        <div class="type_msg">
            <mat-form-field appearance="outline">
                <mat-label>{{'verifications.verification' | translate}}</mat-label>
                <mat-select [(ngModel)]="currentVerificationTypeId">
                    <mat-option *ngFor="let verificationTypeId of verifications" [value]="verificationTypeId">
                        {{'verifications.codes.' + verificationTypeId | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Nota verifica</mat-label>
                <textarea matInput [placeholder]="'Inserisci nota'" [(ngModel)]="currentNote"></textarea>
            </mat-form-field>
        </div>

    </ng-template>


</div>
<div mat-dialog-actions>

    <div class="d-flex justify-content-end w-100">
        <button mat-stroked-button class="mr-2" (click)="onNoClick()">{{'operations.cancel' | translate}}</button>
        <button mat-flat-button color="primary" (click)="save()" [disabled]="!currentNote">
            <fa-icon class="mr-2" [icon]="['fas', 'paper-plane']"></fa-icon> {{'operations.save' | translate}}
        </button>
    </div>

</div>