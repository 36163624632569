import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';

type verbs = 'post' | 'get' | 'insert' | 'delete' | 'put';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    public serverPathPrivate: string;
    public serverPathPublic: string;;

    constructor(private http: HttpClient) {
        this.serverPathPrivate = environment.privateApiUrl;
        this.serverPathPublic = environment.publicApiUrl;

        // For only public projects
        if (this.serverPathPublic && !this.serverPathPrivate) {
            this.serverPathPrivate = this.serverPathPublic;
        }
    }

    /**
     * execRequest
     * @param operation method operation
     * @param endPoint concat to server url
     * @param data payload for post/insert calls
     */
    execRequest(operation: verbs, endPoint: string, data?: any): Observable<any> {

        return this.http[operation](this.serverPathPrivate + '/' + endPoint, data);
    }

    /**
     * execPublicRequest
     * @param operation method operation
     * @param endPoint concat to server url
     * @param data payload for post/insert calls
     */
    execPublicRequest(operation: verbs, endPoint: string, data?: any): Observable<any> {

        return this.http[operation](this.serverPathPublic + '/' + endPoint, data);
    }

    /**
     * execRequestExt
     * @param operation method operation
     * @param path complete path to request
     * @param data payload for post/insert calls
     */
    execRequestExt(operation: verbs, path: string, data?: any): Observable<any> {

        return this.http[operation](path, data);
    }

    /**
      * update file
      * @param operation method operation
      * @param endPoint concat to server url
      * @param data payload with file in it { file: '...', p1: '..', ...}
      */
    updateFile(operation: verbs, endPoint: string, data: any): Observable<boolean> {

        return this.http[operation](this.serverPathPrivate + '/' + endPoint,
            this.createFormData(data));
    }

    /**
     * update file for public scopes
     * @param operation method operation
     * @param endPoint concat to server url
     * @param data payload with file in it { file: '...', p1: '..', ...}
     */
    updatePublicFile(operation: verbs, endPoint: string, data: any): Observable<boolean> {

        return this.http[operation](this.serverPathPublic + '/' + endPoint,
            this.createFormData(data))
    }

    /**
     * get new formData from params object
     * @param params params to beconverted in formData
     */
    private createFormData(params: any) {
        const formData: FormData = new FormData();
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                if (params[key] && key === 'file' && params[key].name) {
                    formData.append(key, params[key], params[key].name);
                } else {
                    formData.append(key, params[key]);
                }
            }
        }
        return formData;
    }

    /**
     * downloadFile
     * @param operation method operation
     * @param endPoint concat to server url
     * @param fileName name of downloaded file
     * @param typeOfFile file extention
     * @code TO IMPLEMENT ERROR HANDLE
     *  const reader = new FileReader();
     *  reader.readAsText(error.error);
     *  reader.onload = (e => { 
     *      const res: any = (e.target).result;
     *      error.error = JSON.parse(res);
     *      // MANAGE ERROR
     *  }
     */
    downloadFile(operation: verbs, endPoint: string, fileName: string, typeOfFile: string = 'application/pdf'): Observable<any> {

        const callParams: any = [`${this.serverPathPrivate}/${endPoint}`];

        return this.http[operation](...callParams, { responseType: 'blob' })
            .pipe(tap((response) => this.doDownload(response, fileName, typeOfFile)));
    }

    /**
    * downloadFile
    * @param operation method operation
    * @param endPoint concat to server url
    * @param fileName name of downloaded file
    * @param typeOfFile file extention
    * @code TO IMPLEMENT ERROR HANDLE
    *  const reader = new FileReader();
    *  reader.readAsText(error.error);
    *  reader.onload = (e => { 
    *      const res: any = (e.target).result;
    *      error.error = JSON.parse(res);
    *      // MANAGE ERROR
    *  }
    */
    downloadPublicFile(operation: verbs, endPoint: string, fileName: string, typeOfFile: string = 'application/pdf'): Observable<any> {

        const callParams: any = [`${this.serverPathPublic}/${endPoint}`];

        return this.http[operation](...callParams, { responseType: 'blob' })
            .pipe(tap((response) => this.doDownload(response, fileName, typeOfFile)));
    }

    /**
     * getCompleteUrlByEndPoint
     * @param endPoint concat to server url
     */
    getCompleteUrlByEndPoint(endPoint: string): string {
        const basePath = window.location.href.split('#')[0];
        return basePath + endPoint;
    }


    doDownload(response: any, fileName: string, typeOfFile: string) {
        const newBlob = new Blob([response], { type: typeOfFile });

        // IE doesn't allow using a blob object directly as link href
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }

}
