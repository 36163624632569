<div class="menu-container" [@slide]="(menu$ | async) ? 'slideIn' : ''">
  <ul class="menu">
    <li class="nav-close-button py-2 pr-2">
      <fa-icon [icon]="['fas', 'times']" (click)="closeMenu()">
      </fa-icon>
    </li>

    <app-menu></app-menu>
  </ul>
</div>

<div *ngIf="(menu$ | async)" class="menu-vail" (click)="closeMenu()"></div>