import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataRouteService } from '@shared/services/data-route.service';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-structure',
    templateUrl: './structure.component.html'
})
export class StructureComponent {

    hasMenu: boolean = false;

    constructor(private route: ActivatedRoute, private router: Router, private dataRouteService: DataRouteService) {

        this.router.events
        .pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            mergeMap((route) => route.data))
        .subscribe((event) => {
            // In here you have the complete controll of data in routes
            this.dataRouteService.dataRoute = event;
            this.hasMenu = event.menu;
        })
    }

}
