
export interface IVerificationConfig {
  verifications: IStepVerificationConfig[];
  groups: IVerificationGroupsConfigs;
}

export interface IVerificationGroupsConfigs {
  requirements: string[];
  sal: string[];
  endWorks: string[];
}

export interface IStatesConfig {
  icon: string[];
  type: string;
}

export interface IStepVerificationConfig {
  code: string;
  label: string;
  route: string;
}

export interface IStepVerification extends IStepVerificationConfig {
  id: string;
  completed: boolean;
  warning: boolean;
  documents: Document[];
}

export interface IVerifications {
  id: string;
  requirementsState?: number;
  salState?: number;
  endWorksState?: number;
  salNumber?: number;
  currentSal?: number;

  urbanistic: IStepVerification;
  technical: IStepVerification;
  swornStatements: IStepVerification;
  costs: IStepVerification;
  sal: IStepVerification;
  endWorks: IStepVerification;

}

export class Verifications implements IVerifications {
  id: string;

  urbanistic: IStepVerification;
  technical: IStepVerification;
  swornStatements: IStepVerification;
  costs: IStepVerification;
  sal: IStepVerification;
  endWorks: IStepVerification;
  salNumber?: number;
}


export interface IVerificationsGroups {

  requirements: IStepVerification[];
  sal: IStepVerification[];
  endWorks: IStepVerification[];

}

export class VerificationsGroups implements IVerificationsGroups {
  requirements: IStepVerification[];
  sal: IStepVerification[];
  endWorks: IStepVerification[];

  constructor() {
    this.requirements = [];
    this.sal = [];
    this.endWorks = [];
  }
}
