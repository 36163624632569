import { Injectable } from '@angular/core';
import {
  IVerifications, IVerificationConfig, IStatesConfig, IStepVerification,
  Verifications, VerificationsGroups, IStepVerificationConfig, IVerificationGroupsConfigs
} from '@models/verifications/verifications.model';
import { RequestService } from '@shared/services/request.service';
import { BehaviorSubject, of } from 'rxjs';
import { cloneDeep } from 'lodash';

import verificationsConfigs from '@configs/verifications.json';
import { IDocumentInfo, IFileToUpload } from '@models/file.model';

@Injectable({
  providedIn: 'root'
})
export class VerificationsService {

  practiceId: number;
  //salNumber: number;

  verifications: IVerifications;
  verificationsGroups$: BehaviorSubject<VerificationsGroups>;

  verificationsConfigs: IStepVerificationConfig[];
  verificationsGroupsConfigs: IVerificationGroupsConfigs;

  statesConfigs: IStatesConfig[];
  verifications$: BehaviorSubject<IVerifications>;


  constructor(private requestService: RequestService) {
    this.verificationsConfigs = cloneDeep(verificationsConfigs.verifications);
    this.verificationsGroupsConfigs = cloneDeep(verificationsConfigs.groups)


    this.verifications = new Verifications();
    this.verificationsGroups$ = new BehaviorSubject(new VerificationsGroups());
    this.verifications$ = new BehaviorSubject(null);
  }

  get verificationsGroups() {
    return this.verificationsGroups$.getValue();
  }

  set verificationsGroups(verificationsGroups: VerificationsGroups) {
    this.verificationsGroups$.next(verificationsGroups);
  }

  initModel(verifications: IVerifications, practiceId: number = this.practiceId) {
    this.verifications$.next(verifications);
    this.practiceId = practiceId;

    const verificationsList = this.verificationsConfigs.map((verification) => {

      const currentVerification = cloneDeep(verifications[verification.code]);
      const stepVerification: IStepVerification = {
        ...verification,
        ...currentVerification,
        documents: currentVerification?.documents || []
      }

      this.verifications[verification.code] = stepVerification
      return stepVerification;
    });

    const verificationsGroups: any = {};

    for (let key in this.verificationsGroupsConfigs) {
      verificationsGroups[key] = this.verificationsGroupsConfigs[key].map((verificationName) => {
        return verificationsList.find((verification) => verification.code == verificationName);
      })
    }

    this.verificationsGroups = verificationsGroups;
  }

  getVerificationId(verificationCode: string) {
    return this.verifications[verificationCode].id;
  }

  getDocuments(verificationCode: string) {
    return cloneDeep(this.verifications[verificationCode].documents);
  }

  getVerifications(practiceId?: number) {
    return this.requestService.execRequest('get', `verifications/${practiceId || this.practiceId}`);
  }

  getVerificationDocuments(verificationType: string, practiceId: number) {
    return this.requestService.execRequest('get', `documents/${this.getVerificationId(verificationType)}/${practiceId}`)
  }

  downloadDocument(document: IDocumentInfo) {
    const { documentId, documentName, documentType } = document;
    this.requestService.downloadFile('get', `documents/download/${documentId}`, documentName, documentType).subscribe();
  }

  saveDocument(verificationType: string, fileToUpload: IFileToUpload, groupid = "") {
    return this.requestService.updateFile('post', 'documents/upload', {
      documentTypeId: fileToUpload.id,
      practiceId: this.practiceId,
      verificationType: this.getVerificationId(verificationType),
      file: fileToUpload.file,
      groupid: groupid
    });
  }

  deleteDocument(documentId: number) {
    return this.requestService.execRequest('delete', `documents/${documentId}`)
  }

  changeStateRequipments(practiceId: number, groupName: string, stateValue: number) {
    return this.requestService.execRequest('put', 'practice/changestaterequipments', { id: practiceId, groupName, stateValue });

  }

}


