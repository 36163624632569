<div class="verification-group-page">
    <nav mat-tab-nav-bar *ngIf="verificationsGroups$ | async as verificationsGroups">
        <a mat-tab-link *ngFor="let verification of verificationsGroups.endWorks" [routerLink]="verification.route"
            routerLinkActive #rla="routerLinkActive" [active]="rla.isActive" [disabled]="verification.disabled">
            <fa-icon *ngIf="verification.completed" class="link-icon confirm mr-2" [icon]="['fas', 'check']"></fa-icon>
            <fa-icon *ngIf="verification.warning" class="link-icon warning mr-2"
                [icon]="['fas', 'exclamation-triangle']">
            </fa-icon>
            {{verification.label | translate}}
        </a>
    </nav>
    <div *ngIf="inLoading; else loaded" class="loader-container">
        <app-local-loader></app-local-loader>
    </div>
    <ng-template #loaded>
        <router-outlet></router-outlet>
    </ng-template>
</div>