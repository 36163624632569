import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt, 'it');

@Injectable({
    providedIn: 'root'
})
export class LanguagesService {

    selectedlanguage$: BehaviorSubject<string>

    private _languages: Array<string>;

    constructor(private translate: TranslateService) {
        this.selectedlanguage$ = new BehaviorSubject('it');
        this._languages = ['it'];
    }

    get languages(): Array<string> {
        return [...this._languages];
    }

    initLanguage(language: string) {
        this.translate.setDefaultLang(language);
        this.selectLanguage(language);
    }

    selectLanguage(language: string) {
        if (this._languages.indexOf(language) == -1) {
            console.error(language, ' Not supported');
        } else {
            this.selectedlanguage$.next(language);
            this.translate.use(language);
            moment.locale(language);
        }
    }
}