import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { plannerTram } from './tram/tram';
import { plannerWalkingMan } from './walking-man/walking-man';

export function initializeIcons(library: FaIconLibrary) {
    // TODO: cercare in tutto l'applicativo le icone ed importarle una ad una
    library.addIconPacks(fas, far, fab);

    //Planner icons
    library.addIcons(plannerTram);
    library.addIcons(plannerWalkingMan);
}