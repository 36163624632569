import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';
import { AuthService } from '@security/_services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { Role, User } from '@models/user.model';
import { UserService } from '@security/_services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() menu: boolean;

    currentUser$: BehaviorSubject<User>;
    currentRole$: BehaviorSubject<Role>;

    constructor(private utils: UtilsService, private authService: AuthService, private userService: UserService) { 
        this.currentUser$ = this.userService.currentUser$;
        this.currentRole$ = this.userService.currentRole$;
    }

    ngOnInit() {
    }

    toggleMenu() {
        this.utils.toggleMenu();
    }

    logout(){
        this.authService.logout();
    }
}
