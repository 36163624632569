<footer class="mt-5 pt-5 pb-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-sm-12">
                <h2 class="project-title">EcoSB</h2>
                <p class="pr-5 text-white-50">Per i tuoi lavori con il Super Bonus 110%, EcoSB mette al tuo servizio una
                    team di professionisti multidisciplinare, che ti seguiranno dalla fase della fattibilità fino alla
                    cessione del credito, in modo rapido, sicuro e facile!</p>
            </div>
            <div class="col-lg-4 col-sm-12 links">
                <ul class="">
                    <li>
                        <a [routerLink]="[ '/cms', 'privacy' ]">Privacy</a>
                    </li>
                    <li>
                        <a [routerLink]="[ '/cms', 'cookie-policy' ]">Cookie policy</a>
                    </li>
                    <li>
                        <a [routerLink]="[ '/cms', 'contacts' ]">Contatti</a>
                    </li>
                </ul>
            </div>
           
        </div>
        <div class="row mt-4">
            <div class="col copyright">
                <p><small class="text-white-50">© 2020. All Rights Reserved. EcoSB - P.iva 15855161004 N. Rea RM – 1618610</small></p>
            </div>
        </div>
    </div>
</footer>