import { Component } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';
import { BehaviorSubject } from 'rxjs';
import { MenuService } from '../../_services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent {

  menuVoices$: BehaviorSubject<any>;

  constructor(private utils: UtilsService, private menuService: MenuService) {
    this.menuVoices$ = this.menuService.menuVoices$;
  }

  closeMenu(){
    this.utils.openMenu(false);
  }

}
