import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '@shared/modals/info-modal/info-modal.component';
import { finalize } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    inLoading$: BehaviorSubject<boolean>;
    menu$: BehaviorSubject<boolean>;
    modals: any;
    snaks: any = {};

    constructor(public dialog: MatDialog, private _snackBar: MatSnackBar,
        private translateService: TranslateService) {
        this.inLoading$ = new BehaviorSubject(false);
        this.menu$ = new BehaviorSubject(false);

        this.modals = {
            info: {
                component: InfoModalComponent
            }
        }

        // TODO fare configs
        this.snaks = {
            error: {
                title: 'operations.operation-fail',
                class: 'snak-error',
                action: 'operations.close'
            },
            success: {
                title: 'operations.operation-success',
                class: 'snak-success',
                action: 'operations.close'
            }
        }

    }

    inLoading(value: boolean) {
        this.inLoading$.next(value);
    }

    openMenu(isOpen: boolean) {
        this.menu$.next(isOpen);
    }

    toggleMenu() {
        this.menu$.next(!this.menu$.getValue());
    }

    scrollTo(elementName: string) {
        const element = document.querySelector(elementName);
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }


    openModal(component: any, data: any = {}, cssClass?: string, onClose?: Function): void {
        let currentModal = this.modals[component];
        if (typeof component != 'string') {
            currentModal = { component };
        }
        if (!currentModal) return;

        const modalRef = this.dialog.open(currentModal.component,
            { data, panelClass: cssClass || currentModal.cssClass }
        );

        const sub = modalRef.afterClosed()
            .pipe(finalize(() => sub.unsubscribe()))
            .subscribe(result => onClose ? onClose(result) : '');
    }

    openDefaultSnackBar(type: string, time: number = 2000, message?: string) {
        const snak = this.snaks[type];

        if (snak) {
            const title = message || this.translateService.instant(snak.title);
            const action = this.translateService.instant(snak.action);

            this._snackBar.open(title, action, {
                duration: time,
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: snak.class
            });
        } else {
            console.error(`snak typed ${type} does not exist`);
        }
    }

    openSnackBar(title: string, panelClass: string = '', action?: string, time: number = 2000) {

        title = this.translateService.instant(title);
        action = action ? this.translateService.instant(action) : action;

        this._snackBar.open(title, action, {
            duration: time,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass
        });
    }


}