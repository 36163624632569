import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import menuConfig from '@configs/menu.json';

@Injectable()
export class MenuService {

    menuVoices$: BehaviorSubject<Array<{
        label: string;
        route: string[];
    }>>;

    constructor() {

        this.menuVoices$ = new BehaviorSubject(menuConfig.voices);
    }
}