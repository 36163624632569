import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Role, User } from '@models/user.model';
import { matchRule } from '@shared/utils/utils';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    chooseRole$: BehaviorSubject<boolean>;
    currentUser$: BehaviorSubject<User>;
    currentRole$: BehaviorSubject<Role>;
    roles: Role[] = [];
    isInvalid: boolean = false;

    constructor() {
        this.chooseRole$ = new BehaviorSubject<boolean>(false);
        this.currentUser$ = new BehaviorSubject<User>(null);
        this.currentRole$ = new BehaviorSubject<Role>(null);
    }

    get currentUser(): User {
        return this.currentUser$.getValue();
    }

    set currentUser(user: User) {
        this.currentUser$.next(user);
    }

    get currentRole(): Role {
        return this.currentRole$.getValue();
    }

    set currentRole(role: Role) {
        this.currentRole$.next(role);
    }

    hasRoles(roleNames: string[]) {
        return roleNames.findIndex((roleName) => matchRule(this.currentRole?.code || 'none', roleName)) != -1;
    }

    consumeUser(user: User) {
        const { roles } = user;
        this.currentUser = user;
        this.currentRole = { ...roles[0] };
        this.roles = roles;

        if (this.hasRoles(['ROLE_INVALID'])) {
            this.isInvalid = true;
        } else {
            this.isInvalid = false;
        }

        if (this.roles.length) {
            this.chooseRole$.next(true);
        }
    }

    resetUser() {
        this.currentUser = null;
        this.currentRole = null;
        this.chooseRole$.next(false);
    }

}