import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { DataRouteService } from '@shared/services/data-route.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-practice-states-stepper',
  templateUrl: 'practice-states-stepper.component.html',
  styleUrls: ['practice-states-stepper.component.scss']
})
export class PracticeStatesStepperComponent implements AfterViewInit {

  @Input() mode: 'compact' | 'extended' = 'compact';
  @Input() operation: 'info' | 'navigation' = 'info';

  @Input() requirementsState: number = 0;
  @Input() salState: number = 0;
  @Input() endWorksState: number = 0;

  @Output('onSelectState') onSelectState: EventEmitter<any>;
  @ViewChild('requirementsMenu', { read: MatMenuTrigger, static: false }) requirementsMenu: MatMenuTrigger;
  @ViewChild('salMenu', { read: MatMenuTrigger, static: false }) salMenu: MatMenuTrigger;
  @ViewChild('endWorksMenu', { read: MatMenuTrigger, static: false }) endWorksMenu: MatMenuTrigger;

  dataRoute$: BehaviorSubject<any>;
  currentState: number;
  currentGroupName: string;
  routes = {
    requirements: 'requirements',
    sal: 'sal',
    endWorks: 'endWorks'
  }

  constructor(private router: Router, private route: ActivatedRoute, private dataRouteService: DataRouteService) {
    this.onSelectState = new EventEmitter();
    this.dataRoute$ = this.dataRouteService.dataRoute$;
  }
  ngAfterViewInit(): void {
    this.onSelectState.emit(this.dataRoute$.getValue().groupName);
  }

  onGroupClick(groupName: string, state: number) {
    if (!state) {
      this[groupName + 'Menu'].closeMenu();
      return;
    }
    this.onSelectState.emit(groupName);
    switch (this.operation) {
      case 'info':
        this.currentState = this[groupName + 'State'];
        this.currentGroupName = groupName;
        break;
      case 'navigation':
        this[groupName + 'Menu'].closeMenu();
        this.router.navigate([groupName], { relativeTo: this.route });
        break;
    }

  }

}
