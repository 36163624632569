<mat-toolbar color="primary">

    <mat-toolbar-row class="container-fluid header">

        <button *ngIf="menu" mat-icon-button class="d-md-none" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>

        <span class="example-spacer"></span>

        <ng-container *ngIf="currentUser$ | async as currentUser">
            <span class="user-info">{{currentUser.name}} {{currentUser.surname}}</span>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User button">
                <mat-icon>person</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <div class="btn-menu mx-3 px-4 pt-4 pb-2">
                    Ruolo {{(currentRole$ | async)?.description}}
                </div>
                <button class="btn-menu" mat-menu-item (click)="logout()">
                    <span class="mx-3">Logout</span>
                    <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
                </button>
            </mat-menu>
        </ng-container>


    </mat-toolbar-row>

</mat-toolbar>