import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { LayoutModule } from '@layout/layout.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { initializeIcons } from '@assets/fa-icons/fa-icons';
import { SecurityModule } from '@security/security.module';
import { JwtInterceptor } from '@security/_services/jwt/jwt.interceptor';
import { IndexComponent } from '@pages/index/index.component';
import { HttpLoaderFactory, appInitializerFactory } from '@shared/services/ngx-translate.service';
import { appInitialize } from '@security/_services/app-initialize';
import { AuthService } from '@security/_services/auth.service';
import { ErrorInterceptor } from '@security/_services/error.interceptor';
// import { NgxEchartsModule } from 'ngx-echarts';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { VerificationsComponent } from '@pages/verifications/verifications/verifications.component';


// import echartsTheme from '@configs/echarts-theme.json';
import { VerificationsGroupsModule } from '@pages/verifications/_verifications-groups/verifications-groups.module';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    VerificationsComponent
  ],
  imports: [
    SharedPipesModule,
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    SharedModule,
    LayoutModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SecurityModule,
    // NgxEchartsModule.forRoot({
    //   echarts: () => import('echarts').then(echarts => {
    //     echarts.registerTheme(echartsTheme.themeName, echartsTheme.theme);
    //     return echarts;
    //   }),

    // }),
    VerificationsGroupsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialize,
      deps: [AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    initializeIcons(library);
  }
}
