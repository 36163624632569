import { Component } from '@angular/core';
import { LanguagesService } from '@shared/services/languages.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private languagesService: LanguagesService) {
    this.languagesService.initLanguage('it');
  }
}
