import { Pipe, PipeTransform } from "@angular/core";
import { UserService } from "@security/_services/user.service";

@Pipe({
    name: 'roleValidator'
})
export class RoleValidatorPipe implements PipeTransform {

    constructor(private userService: UserService) { }

    transform(roles: string | string[], not: boolean = false) {
        if (!roles) return true;
        if (typeof roles == 'string') {
            roles = [roles];
        }

        return not ? !this.userService.hasRoles(roles) : this.userService.hasRoles(roles);

    }
}