<div class="mt-2">
    <div class="logo-container">
        <div class="logo">
            <img src="assets/icons/logo_ecosb.svg">
        </div>
    </div>
    <div class="menu">
        <ng-container *ngFor="let voice of menuVoices$ | async">
            <div *ngIf="voice.roles | roleValidator" class="voice" routerLinkActive="active" [routerLink]="voice.route"
                (click)="closeMenu()">
                {{voice.label | translate}}
            </div>
        </ng-container>

    </div>
</div>