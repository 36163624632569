import { Component } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent {

  inLoading$: BehaviorSubject<boolean>;

  constructor(private utils: UtilsService) {
    this.inLoading$ = this.utils.inLoading$;
  }
}