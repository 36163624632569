import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { MenuModule } from './menu/menu.module';
import { StructureComponent } from './structure/structure.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    WorkInProgressComponent,
    NotFoundComponent,
    StructureComponent
  ],
  imports: [
    SharedModule,
    MenuModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    WorkInProgressComponent,
    NotFoundComponent,
    StructureComponent
  ]
})
export class LayoutModule { }
