import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectivesModule } from '@shared/directives/dnd.module';
import { MaterialModule } from '@shared/material/material.module';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { HexagonIconComponent } from './hexagon-icon/hexagon-icon.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';

import { LocalLoaderComponent } from './local-loader/local-loader.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { SelectDeferredComponent } from './select-deferred/select-deferred.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { PracticeStatesStepperComponent } from './practice-states-stepper/practice-states-stepper.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        SharedDirectivesModule,
        MaterialModule,
        FontAwesomeModule,
        RoundProgressModule,
        SharedPipesModule
    ],
    declarations: [
        LocalLoaderComponent,
        FileUploaderComponent,
        HexagonIconComponent,
        PageLoaderComponent,
        SelectDeferredComponent,
        SearchPanelComponent,
        PracticeStatesStepperComponent
    ],
    exports: [
        LocalLoaderComponent,
        FileUploaderComponent,
        HexagonIconComponent,
        PageLoaderComponent,
        SelectDeferredComponent,
        SearchPanelComponent,
        PracticeStatesStepperComponent
    ]
})
export class SharedComponentsModule { }
