import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EndWorksGroupComponent } from './end-works-group/end-works-group.component';

import { RequirementsGroupComponent } from './requirements-group/requirements-group.component';
import { SalGroupComponent } from './sal-group/sal-group.component';

@NgModule({
    declarations: [
        RequirementsGroupComponent,
        SalGroupComponent,
        EndWorksGroupComponent
    ],
    exports: [
        RequirementsGroupComponent,
        SalGroupComponent,
        EndWorksGroupComponent
    ],
    imports: [SharedModule]
})
export class VerificationsGroupsModule { }
