import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/dnd.module';
import { NoteModalComponent } from './modals/note-modal/note-modal.component';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        InfoModalComponent,
        NoteModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MaterialModule,
        TranslateModule,
        FontAwesomeModule,
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectivesModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        TranslateModule,
        FontAwesomeModule,
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectivesModule,
        NoteModalComponent
    ]
})
export class SharedModule { }
