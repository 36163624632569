import { Routes } from '@angular/router';
import { AuthGuard } from '@security/_services/auth.guard';
import { EndWorksGroupComponent } from './_verifications-groups/end-works-group/end-works-group.component';
import { RequirementsGroupComponent } from './_verifications-groups/requirements-group/requirements-group.component';
import { SalGroupComponent } from './_verifications-groups/sal-group/sal-group.component';

export const VerificationsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'requirements'
  },
  {
    path: 'requirements',
    component: RequirementsGroupComponent,
    data: { groupName: 'requirements' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'urbanistic-verification'
      },
      {
        path: 'urbanistic-verification',
        canActivate: [AuthGuard],
        loadChildren: () => import('@verifications/urbanistic-verification/urbanistic-verification.module').then(m => m.UrbanisticVerificationModule),
        data: { preload: true }
      },
      {
        path: 'technical-verification',
        canActivate: [AuthGuard],
        loadChildren: () => import('@verifications/technical-verification/technical-verification.module').then(m => m.TechnicalVerificationModule),
        data: { preload: true }
      },
      {
        path: 'sworn-statements-verification',
        canActivate: [AuthGuard],
        loadChildren: () => import('@verifications/sworn-statements-verification/sworn-statements-verification.module').then(m => m.SwornStatementsVerificationModule),
        data: { preload: true }
      },
      {
        path: 'costs-verification',
        canActivate: [AuthGuard],
        loadChildren: () => import('@verifications/costs-verification/costs-verification.module').then(m => m.CostsVerificationModule),
        data: { preload: true }
      }
    ]
  },
  {
    path: 'sal',
    component: SalGroupComponent,
    data: { groupName: 'sal' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'sal-verification'
      },
      {
        path: 'sal-verification',
        canActivate: [AuthGuard],
        loadChildren: () => import('@verifications/sal-verification/sal-verification.module').then(m => m.SalVerificationModule),
        data: { preload: true }
      }
      // {
      //   path: 'sal-verification-2',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('@verifications/sal-verification/sal-verification.module').then(m => m.SalVerificationModule),
      //   data: { preload: true }
      // },
    ]
  },
  {
    path: 'endWorks',
    component: EndWorksGroupComponent,
    data: { groupName: 'endWorks' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'end-works-verification'
      },
      {
        path: 'end-works-verification',
        canActivate: [AuthGuard],
        loadChildren: () => import('@verifications/end-works-verification/end-works-verification.module').then(m => m.EndWorksVerificationModule),
        data: { preload: true }
      }
    ]
  }
];
