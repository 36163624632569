import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { IStepVerification, IVerifications } from '@models/verifications/verifications.model';
import { PracticesService } from '@pages/practices/_services/practices.service';
import { STATI_VERIFICHE } from '@shared/enum/enumerations';
import { NoteModalComponent } from '@shared/modals/note-modal/note-modal.component';
import { DataRouteService } from '@shared/services/data-route.service';
import { UtilsService } from '@shared/services/utils.service';
import { BehaviorSubject } from 'rxjs';
import { VerificationsService } from '../_services/verifications.service';

@Component({
  selector: 'app-verifications',
  templateUrl: './verifications.component.html',
  styleUrls: ['./verifications.component.scss']
})
export class VerificationsComponent implements OnInit, OnChanges {

  verifications$: BehaviorSubject<IVerifications>;
  dataRoute$: BehaviorSubject<any>;
  enableSendConfirmRequirements: boolean = false;
  enableConfirmRequirements: boolean = false;
  currentPart?: string;
  constructor(
    private dataRouteService: DataRouteService, private utils: UtilsService,
    private verificantionsService: VerificationsService,
    private router: Router) {
    this.verifications$ = this.verificantionsService.verifications$;
    this.dataRoute$ = this.dataRouteService.dataRoute$;


  }
  ngOnInit(): void {
    // this.enableOperation('requirements');
    // this.verifications$.subscribe(v => {

    //   switch (this.dataRoute$.getValue().groupName) {
    //     case 'requirements': {
    //       this.enableSendConfirmRequirements = v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && (!v.requirementsState || v.requirementsState <= STATI_VERIFICHE.ABILITATO);
    //       this.enableConfirmRequirements = v.requirementsState == STATI_VERIFICHE.DA_VERIFICARE;
    //       break;
    //     }
    //     case 'sal': {
    //       this.enableSendConfirmRequirements = v.requirementsState == STATI_VERIFICHE.VERIFICATO && v.sal.completed && v.salState <= STATI_VERIFICHE.ABILITATO
    //       this.enableConfirmRequirements = v.salState == STATI_VERIFICHE.VERIFICATO
    //       break;
    //     }
    //     case 'endWorks': {
    //       this.enableSendConfirmRequirements = v.salState == STATI_VERIFICHE.VERIFICATO && v.endWorks.completed && v.endWorksState <= STATI_VERIFICHE.ABILITATO
    //       this.enableConfirmRequirements = v.endWorksState == STATI_VERIFICHE.VERIFICATO
    //       break;
    //     }
    //     default: {
    //       this.enableSendConfirmRequirements = false;
    //       break;
    //     }
    //   }



    //   // // Verifiche Fattibilità
    //   // this.enableSendConfirmRequirements = v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && v.requirementsState <= 1
    //   //   // Verifiche Sal
    //   //   || (v.requirementsState == 3 && v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && v.sal.completed && v.salState <= 1)
    //   //   // Verifiche FineLavori
    //   //   || (v.requirementsState == 3 && v.salState == 3 && v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && v.sal.completed && v.endWorks.completed && v.endWorksState <= 1);

    //   // // Verifiche Fattibilità
    //   // this.enableConfirmRequirements = (v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && v.requirementsState == 2)
    //   //   // Verifiche Sal
    //   //   || (v.requirementsState == 3 && v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && v.sal.completed && v.salState == 2)
    //   //   // Verifiche FineLavori
    //   //   || (v.requirementsState == 3 && v.salState == 3 && v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && v.sal.completed && v.endWorks.completed && v.endWorksState == 2)
    // }
    // );
  }


  private enableOperation(groupName: string) {
    // this.verifications$.subscribe(v => {

    //   switch (groupName) {
    //     case 'requirements': {
    //       this.enableSendConfirmRequirements = v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && (!v.requirementsState || v.requirementsState <= STATI_VERIFICHE.ABILITATO);
    //       this.enableConfirmRequirements = v.requirementsState == STATI_VERIFICHE.DA_VERIFICARE;
    //       break;
    //     }
    //     case 'sal': {
    //       this.enableSendConfirmRequirements = v.requirementsState == STATI_VERIFICHE.VERIFICATO && v.sal.completed && v.salState <= STATI_VERIFICHE.ABILITATO
    //       this.enableConfirmRequirements = v.salState == STATI_VERIFICHE.VERIFICATO
    //       break;
    //     }
    //     case 'endWorks': {
    //       this.enableSendConfirmRequirements = v.salState == STATI_VERIFICHE.VERIFICATO && v.endWorks.completed && v.endWorksState <= STATI_VERIFICHE.ABILITATO
    //       this.enableConfirmRequirements = v.endWorksState == STATI_VERIFICHE.VERIFICATO
    //       break;
    //     }
    //     default: {
    //       this.enableSendConfirmRequirements = false;
    //       break;
    //     }
    //   }
    // });

  }

  selectedState(groupName: string) {
    this.verifications$.subscribe(v => {

      switch (groupName) {
        case 'requirements': {
          this.enableSendConfirmRequirements = v.costs.completed && v.swornStatements.completed && v.urbanistic.completed && v.technical.completed && (!v.requirementsState || v.requirementsState <= STATI_VERIFICHE.ABILITATO);
          this.enableConfirmRequirements = v.requirementsState == STATI_VERIFICHE.DA_VERIFICARE;
          break;
        }
        case 'sal': {
          this.enableSendConfirmRequirements = v.requirementsState == STATI_VERIFICHE.VERIFICATO && v.sal.completed && v.salState <= STATI_VERIFICHE.ABILITATO
          this.enableConfirmRequirements = v.salState == STATI_VERIFICHE.DA_VERIFICARE;

          this.currentPart = this.fnSalLabel(v.currentSal || 1, v.salNumber);
          break;
        }
        case 'endWorks': {
          this.enableSendConfirmRequirements = v.salState == STATI_VERIFICHE.VERIFICATO && v.endWorks.completed && v.endWorksState <= STATI_VERIFICHE.ABILITATO
          this.enableConfirmRequirements = v.endWorksState == STATI_VERIFICHE.DA_VERIFICARE
          break;
        }
        default: {
          this.enableSendConfirmRequirements = false;
          this.enableConfirmRequirements = false;
          break;
        }
      }
    });
  }
  fnSalLabel(currentSal: number, salNumber: number) {
    if (salNumber > 1) {
      // if (currentSal === salNumber) {
      //   return " Finale";
      // }
      return " " + currentSal;
    }
    return "";
  }

  ngOnChanges() {
    alert('change');
  }

  verifyGroup(groupName: string) {
    this.verificantionsService.changeStateRequipments(this.verificantionsService.practiceId, groupName, 3)
      .subscribe(() => {
        this.utils.openDefaultSnackBar('success');
        this.router.navigate(['/practices']);
      })
  }



  rejectVerifyGroup(groupName: string) {
    this.verificantionsService.changeStateRequipments(this.verificantionsService.practiceId, groupName, 1)
      .subscribe(() => {
        this.utils.openDefaultSnackBar('success');
        this.router.navigate(['/practices']);
      })
  }
  openNote(groupName: string) {
    this.utils.openModal(NoteModalComponent, {
      practiceId: this.verificantionsService.practiceId,
      verifications: this.verificantionsService.verificationsGroups[groupName].map((verification) => verification.id)
    }, 'modal-md')
  }

  unlockNextGroup(groupName: string) {

    this.verificantionsService.changeStateRequipments(this.verificantionsService.practiceId, groupName, 2)
      .subscribe(() => {
        this.utils.openDefaultSnackBar('success');
        this.router.navigate(['/practices']);
      })

  }

}
