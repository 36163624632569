'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'planner';
var iconName = 'tram';
var width = 576;
var height = 512;
var ligatures = [];
var unicode = 'g80d';
var svgPathData = 'M367.837,89.25h-54.824v-51h121.125c6.375,0,12.75,6.375,12.75,12.75c0,10.2,8.926,19.125,19.125,19.125S485.138,61.2,485.138,51c0-28.05-22.949-51-51-51h-280.5c-28.05,0-51,22.95-51,51c0,10.2,8.925,19.125,19.125,19.125c10.2,0,19.125-8.925,19.125-19.125c0-7.65,5.1-12.75,12.75-12.75h121.125v49.725h-54.825c-62.475,0-112.2,51-112.2,112.2v229.501c0,25.5,17.85,47.174,43.35,51l-36.975,70.125c-6.375,12.75-1.275,28.049,10.2,34.424c3.825,2.551,7.65,2.551,11.475,2.551c8.925,0,17.85-5.1,22.95-14.025l7.65-15.301h252.449l7.65,15.301c5.1,8.926,14.025,14.025,22.951,14.025c3.824,0,7.648-1.275,11.475-2.551c12.75-6.375,17.85-21.674,10.199-34.424l-36.975-70.125c24.225-3.826,43.35-25.5,43.35-51V201.45C480.038,138.975,429.038,89.25,367.837,89.25z M398.438,436.051c-20.4,0-36.977-16.576-36.977-36.977c0-20.398,16.576-36.975,36.977-36.975c20.398,0,36.975,16.576,36.975,36.975C435.413,419.475,418.837,436.051,398.438,436.051z M153.638,215.475c0-39.525,31.875-70.125,71.4-70.125h140.25c39.525,0,71.4,31.875,71.4,70.125v62.475c0,14.025-11.477,24.226-25.5,24.226H177.863c-14.025,0-25.5-10.201-25.5-24.226C153.638,277.95,153.638,215.475,153.638,215.475z M189.337,360.824c20.4,0,36.975,16.576,36.975,36.977c0,20.398-16.575,36.975-36.975,36.975c-20.4,0-36.975-16.576-36.975-36.975C152.363,377.4,168.938,360.824,189.337,360.824z M186.788,522.75l20.4-39.525h172.126l20.398,39.525H186.788z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]};

exports.plannerTram = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;

