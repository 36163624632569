<h1 mat-dialog-title>{{(data.title | translate) | title}}</h1>
<div mat-dialog-content>
    <p [innerHtml]="data.description | translate"></p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end" *ngIf="data?.continue; else okThanks">
    <button mat-stroked-button class="mr-2" (click)="onNoClick()">{{'operations.cancel' | translate}}</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">{{'operations.yes-continue' | translate}}</button>
</div>
<ng-template #okThanks>
    <div mat-dialog-actions class="d-flex justify-content-end">
        <button mat-flat-button color="primary" [mat-dialog-close]="true">{{'operations.ok-thanks' | translate}}</button>
    </div>
</ng-template>