import { Component, OnInit } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';
import { BehaviorSubject } from 'rxjs';
import { MenuService } from '../../_services/menu.service';

@Component({
  selector: 'app-desktop-menu',
  templateUrl: './desktop-menu.component.html'
})
export class DesktopMenuComponent implements OnInit {

  isMenuOpen$: BehaviorSubject<boolean>;
  menuVoices$: BehaviorSubject<any>;

  constructor(private utils: UtilsService, private menuService: MenuService) {
    this.menuVoices$ = this.menuService.menuVoices$;
  }

  ngOnInit() {
    this.isMenuOpen$ = this.utils.menu$;
  }

  toggleMenu() {
    this.utils.toggleMenu();
  }

}
