import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from '@shared/services/utils.service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthService } from './auth.service';

import errorsConfigs from '@configs/errors.json';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private utils: UtilsService) {

    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                // retry(1),
                catchError((error: HttpErrorResponse) => {

                    const errorConf = errorsConfigs[error.status];

                    if (errorConf) {
                        if (errorConf.forceLogout) {
                            // TODO: GESTIONE ERRORI CREAZIONE MODALE ERRORE
                            this.authService.logout();
                        }
                    }
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                        this.utils.openDefaultSnackBar('error');
                    }

                    return throwError(errorMessage);
                })
            )
    }
}