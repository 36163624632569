import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { matchRule } from '@shared/utils/utils';
import routesConfigs from '@configs/routes.json';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {

    routesConfigs: { path: string, roles: string[] }[];

    constructor(private authService: AuthService, private router: Router, private userService: UserService) {
        this.routesConfigs = routesConfigs;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        while (route.firstChild) {
            route = route.firstChild;
        }

        const { public: isPublic } = route.data;

        if (isPublic) return true;

        return this.canAccessToPage(state.url)
    }

    private canAccessToPage(url) {

        // if the user is present, block navigation for signUp/signIn
        if (matchRule(url, '/access/*')) {
            if (this.authService.isLoggedIn) {
                this.router.navigate(['/practices']);
                return false;
            } else {
                return true;
            }
        }

        if (!this.authService.isLoggedIn) {
            return false;
        }


        return this.checkRole(url);
    }

    private checkRole(url) {
        const routeConfig = this.routesConfigs.find((route) => matchRule(url, route.path));
        if (routeConfig) {
            const pass = this.userService.hasRoles(routeConfig.roles);
            if(!pass){
                this.router.navigate(['/not-found']);
            }
            return pass
        } else {
            return true;
        }
    }
}