
import { NgModule } from '@angular/core';
import { BuildingIconPipe } from './building-icon/building-icon.pipe';
import { BytesPipe } from './bytes/bytes.pipe';
import { RoleValidatorPipe } from './role-validator/role-validator.pipe';
import { StatesIconPipe } from './states-icon/states-icon.pipe';
import { TitlePipe } from './title/title.pipe';

@NgModule({
    declarations: [
        TitlePipe,
        BuildingIconPipe,
        BytesPipe,
        RoleValidatorPipe,
        StatesIconPipe
    ],
    exports: [
        TitlePipe,
        BuildingIconPipe,
        BytesPipe,
        RoleValidatorPipe,
        StatesIconPipe
    ]
})
export class SharedPipesModule { }
