import { NgModule } from '@angular/core';

import { AuthService } from './_services/auth.service';
import { JwtInterceptor } from './_services/jwt/jwt.interceptor';
import { AuthGuard } from './_services/auth.guard';
import { JwtTokenService } from './_services/jwt/jwt-token.service';
import { ErrorInterceptor } from './_services/error.interceptor';

@NgModule({
    providers: [
        AuthGuard,
        AuthService,
        JwtInterceptor,
        JwtTokenService,
        ErrorInterceptor
    ]
})
export class SecurityModule { }
